export const FETCH_PROJECTS = 'FETCH_ALL';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_ALL_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_ALL_FAILURE';
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';

export const FETCH_BLOGS = 'FETCH_BLOGS';
export const FETCH_BLOGS_SUCCESS = 'FETCH_BLOGS_SUCCESS';
export const FETCH_BLOGS_FAILURE = 'FETCH_BLOGS_FAILURE';
export const FETCH_BLOG = 'FETCH_BLOG';
export const FETCH_BLOG_SUCCESS = 'FETCH_BLOG_SUCCESS';
export const FETCH_BLOG_FAILURE = 'FETCH_BLOG_FAILURE';
export const UPDATE_BLOG = 'UPDATE_BLOG';
